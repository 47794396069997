:root {
  color-scheme: light dark;
}
:root {
  --background-color: #fbfbfb;
  --font-color: black;
  --border-color: black;
  --page-border: #bbbbbb;
  --footer-background-color: #eeeeee;
  --footer-text-color: #333333;
  --icon-invert: 0%;
  --logo-color: #0c4c0c;
}
:root.dark {
  --background-color: #1c1c1c;
  --font-color: #eeeeee;
  --border-color: white;
  --page-border: #404040;
  --footer-background-color: #313131;
  --footer-text-color: #cccccc;
  --icon-invert: 95%;
  --logo-color: #177f17;
}

body {
  color: var(--font-color);
  background: var(--background-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  width: 100%;
  margin: 0px;
  padding: 1em 0em;
  border-bottom: var(--page-border) 1px solid;
}

#header-content {
  text-align: left;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.1em;
  padding: 0px 2px;
}

#logo {
  font-weight: bold;
  color: var(--logo-color);
}

footer {
  width: 100%;
  margin: 0px;
  margin-top: 1em;
  padding: 1em 0em;
  border-top: var(--page-border) 1px solid;
  background-color: var(--footer-background-color);
}

#footer-contents {
  display: flex;
  gap: 2em;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

footer a {
  color: var(--footer-text-color);
  text-decoration: none;
  font-weight: bold;
}

.dark-mode {
  filter: invert(var(--icon-invert));
  position: relative;
  top: -0.2em;
}

#page-content {
  flex: 1;
  margin: 2px;
}

#resources {
  display: none;
}

#status-board {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

#status-board > .details {
  text-align: right;
  margin-top: 0.3em;
}

.labeled-detail {
  padding: 0.35em;
  padding-right: 0em;
  color: var(--font-color);
  text-decoration-style: dotted;
  text-decoration-thickness: 2%;
}

#pingChart {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  width: 100%;
  user-select: none;
}

#pingChart .future {
  background-color: var(--background-color);
}

#pingChart .noresponse {
  background-color: #dddddd;
}

#pingChart .success {
  background-color: #e5f8e9;
}

#pingChart .noconnection {
  background-color: #ffe083;
}

#pingChart .timeout {
  background-color: #ed99a1;
}

#pingChart .row {
  display: flex;
  padding: 0px;
  height: 3em; /* keep in sync with JS */
  margin: 0.8em 0em;
}

#pingChart .minuteLabel {
  width: 6ch;
  flex: 0 0 6ch;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

#pingChart .sec {
  flex: 1 1 0;
}

#pingChart #secondLabels .sec {
  border-top: 1px var(--border-color) solid;
  padding-top: 0.5em;
}

#pingChart .latencyLine {
  /* use px instead of em/px so the height is consistent */
  height: 2px;
  width: 100%;
  background-color: #28a745;
}

#tooltip {
  display: none;
  background: #333;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
}

#tooltip[data-show] {
  display: block;
}
