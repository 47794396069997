:root {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
  }
}

:root {
  --background-color: #fbfbfb;
  --font-color: black;
  --border-color: black;
  --page-border: #bbb;
  --footer-background-color: #eee;
  --footer-text-color: #333;
  --icon-invert: 0%;
  --logo-color: #0c4c0c;
}

:root.dark {
  --background-color: #1c1c1c;
  --font-color: #eee;
  --border-color: white;
  --page-border: #404040;
  --footer-background-color: #313131;
  --footer-text-color: #ccc;
  --icon-invert: 95%;
  --logo-color: #177f17;
}

body {
  color: var(--font-color);
  background: var(--background-color);
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  display: flex;
}

header {
  border-bottom: var(--page-border) 1px solid;
  width: 100%;
  margin: 0;
  padding: 1em 0;
}

#header-content {
  text-align: left;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2px;
  font-size: 1.1em;
}

#logo {
  color: var(--logo-color);
  font-weight: bold;
}

footer {
  border-top: var(--page-border) 1px solid;
  background-color: var(--footer-background-color);
  width: 100%;
  margin: 1em 0 0;
  padding: 1em 0;
}

#footer-contents {
  gap: 2em;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

footer a {
  color: var(--footer-text-color);
  font-weight: bold;
  text-decoration: none;
}

.dark-mode {
  filter: invert(var(--icon-invert));
  position: relative;
  top: -.2em;
}

#page-content {
  flex: 1;
  margin: 2px;
}

#resources {
  display: none;
}

#status-board {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

#status-board > .details {
  text-align: right;
  margin-top: .3em;
}

.labeled-detail {
  color: var(--font-color);
  padding: .35em 0 .35em .35em;
  text-decoration-style: dotted;
  text-decoration-thickness: .02em;
}

#pingChart {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

#pingChart .future {
  background-color: var(--background-color);
}

#pingChart .noresponse {
  background-color: #ddd;
}

#pingChart .success {
  background-color: #e5f8e9;
}

#pingChart .noconnection {
  background-color: #ffe083;
}

#pingChart .timeout {
  background-color: #ed99a1;
}

#pingChart .row {
  height: 3em;
  margin: .8em 0;
  padding: 0;
  display: flex;
}

#pingChart .minuteLabel {
  flex-direction: column;
  flex: 0 0 6ch;
  place-content: center;
  width: 6ch;
  display: flex;
}

#pingChart .sec {
  flex: 1 1 0;
}

#pingChart #secondLabels .sec {
  border-top: 1px var(--border-color) solid;
  padding-top: .5em;
}

#pingChart .latencyLine {
  background-color: #28a745;
  width: 100%;
  height: 2px;
}

#tooltip {
  color: #fff;
  background: #333;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: bold;
  display: none;
}

#tooltip[data-show] {
  display: block;
}
/*# sourceMappingURL=about.69a0382a.css.map */
